.dashborard{
    background-color:#ebebeb;
    height: 65vh;
    border-radius: 1rem;
    padding: 1%;
    display: flex;
    flex-direction: column;
}

.scroll{
    overflow: hidden;
    overflow-y: auto;
}

.scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scroll::-webkit-scrollbar:vertical {
    width:8px;
}


.scroll::-webkit-scrollbar-thumb {
    background-color: #848484;
    border-radius: 20px;
}