.banner {
    width: 100%;
    border-radius: var(--border-radius-1);
    overflow: hidden;
    box-shadow: 0 0 20px var(--gray-shadow-1);

    display: flex;
    position: relative;
}

.informacion_banner {
    width: 400px;
    padding: 30px;
}

.shadow_banner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg, rgba(41,41,41,1) 4%, 
        rgba(41,41,41,0.7903536414565826) 24%, 
        rgba(41,41,41,0.47942927170868344) 42%, 
        rgba(41,41,41,0) 57%
    );
}

.title_informacion_banner {
    color: #FFFF;
    font-size: 2.5em;
    margin: 0 0 10px  0;
    font-family: 'coco gothic';
}

.informacion_banner_text {
    color: #FFFF;
    font-size: 1.3em;
    line-height: 25px;
    font-family: 'coco gothic';
}

.image_banner {
    position: absolute;
    z-index: -2;
    width: 100%;
    height:100%;
    object-fit: cover;
    object-position: center;
}