.input_checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox {
    padding: 10px;
}

.name_checkbox {
    font-family: var(--font-1);
    color: var(--gray-text-2);
    font-size: 18px;
}