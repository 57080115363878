.first_letter_name_user {
    color: #FFFF;
    font-size: 7em;
    margin: 20px 0 0 0;
    font-family: var(--font-1);
} 

.image_profile_user_ {
    height: 100%;
}

.image_user_ {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.small_profile {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}

.small_profile:hover {
    cursor: pointer;
}

/* Small design */

.small_profile .first_letter_name_user {
    font-size: 24px;
    margin: 5px 0 0 0;
    font-weight: 400;
}

.small_profile .image_user_ {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

/* Big design */

.big_profile {
    height: 100%;
}

.big_profile .first_letter_name_user {
    font-size: 11em;
    font-weight: 400;
}

.medium_profile {
    height: 200px;
}