.center_chat {
    max-width: var(--max-width-container);
    height: calc( 100vh - 150px);
    margin: 2em auto;
    padding: 0 20px;

    display: flex;
    gap: 20px;
}

.aside_chat {
    width: 30%;
    padding: 25px;
}

.header_aside_chat {
    padding: 0 0 20px 0;
}

.cards_aside_chat {
    height: 300px;
    gap: 15px;

    display: flex;
    flex-direction: column;
}

.main_messages {
    width: 70%;
}

.header_messages_chat {
    padding: 25px;
    border-bottom: var(--gray-border-1);
}

.information_user_header_chat {
    display: flex;
    align-items: center;
    gap: 15px;
}

.name_user_header_chat {
    font-size: 25px;
    font-family: var(--font-1);
    color: var(--gray-text-2);
}

.main_messages_chat {
    height: calc(100% - 41px);
    padding: 0 20px 20px 20px;

    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.messages_chat {
    height: calc(100% - 95px);
    overflow-y: scroll;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 15px;
}

.messages_chat::-webkit-scrollbar {
    width: 5px;
}

.messages_chat::-webkit-scrollbar-track {
    /* background: #f0f0f0; */
    border-radius: 2px;
}

.messages_chat::-webkit-scrollbar-thumb {
    background: #c5c5c552;
    border-radius: 2px;
}

.input_message_chat {
    display: flex;
}

.message_chat {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.icon_message_chat {
    width: 300px;
}

.title_app_info_chat {
    font-size: 3em;
    font-family: var(--font-2);
    color: var(--gray-text-2);
}

.info_chat {
    font-family: var(--font-1);
    width: 400px;
    border-top: 2px solid #f0f0f0;
    padding: 10px 0;
    color: #b8b8b8;
}