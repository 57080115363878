.input_textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 500px;
    padding: 13px;
    border: none;
    border-radius: 5px;
    outline: none;
    background: #f9f9f9;
    font-family: var(--font-1);
    font-size: 18px;
}