.center_main_add_service {
    max-width: var(--max-width-container);
    margin: 1em auto;
    padding: 0 20px;

    display: flex;
    gap: 20px;
}

.image_add {
    width: 50%;
    padding: 20px;
}

#input_file_image {
    display: none;
}

.drag_drop_image { 
    border: 2px dashed #afafaf;
    border-radius: 10px;
    height: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon_add_image {
    width: 60px;
}

.info_drag_drop_image {
    color: var(--gray-text-3);
    font-size: 20px;
    font-family: var(--font-1);
    margin: 1em 0 0 0;
    width: 300px;
    text-align: center;
}

.form_add_service {
    width: 50%;
}

.padding_form_add_service {
    padding: 30px;
}

.title_add_service {
    font-size: 25px;
}

.form_register_service {
    height: 400px;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
}

.flex_button_register_service {
    display: flex;
    flex-direction: column;
}