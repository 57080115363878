.loading_card {
    position: relative;
}

.header_load {
    
    background: #f5f5f5;
}

.body_load {
    background: #f0f0f0;
    height: 100px;
}

.text_load {
    background: #e2e2e2;
    border-radius: 5px;
    width: 60%;
    height: 18px;
}

.name_load {
    margin: 10px 0 0;
    background: #e2e2e2;
    border-radius: 5px;
    width: 100%;
    height: 30px;
}

.animation_load {
    top: -100px;
    left: 50px;
    width: 200px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 7%, rgba(230, 230, 230, 0.846) 23%, rgba(230, 230, 230, 0.846) 36%,rgba(230, 230, 230, 0.846) 61%, rgba(230, 230, 230, 0.846) 74%, rgba(255,255,255,0) 95%, rgba(255,255,255,0) 100%);
    z-index: 2;
    transform: rotate(4deg) translateX(-20px);
    height: 150%;
    position: absolute;
    animation: card_load infinite 3s;
}

.slow {
    width: 100px;
    animation: card_load infinite 4s;
}

@keyframes card_load {
    0% {
        left: -100%;
    }
    100% {
        left: 110%;
    }
}